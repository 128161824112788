<template>
  <div v-if="loading"><loading-spinner /></div>
  <dynamic-table
    v-else
    :records="this.entries"
    :fields="tableConfig"
    :pagination-page="paginationPage"
    :pagination-total-items="paginationTotalItems"
    :id-name="'fieldwork_id'"
    :from-route="'AdminFieldworks'"
    :row-path="'FieldworksView'"
    @pageChanged="nextPage($event)"
    no-data-msg="No fieldwork to display for the current filter"
    hover
    striped
    pagination
  >
    <template #cell(fieldwork-type)="{ item }">
      <div class="">
        {{ entry_type(item.fieldwork_type_id) }}
      </div>
    </template>
    <template #cell(fieldwork-last-updated)="{ item }">
      <div class="">
        {{ TableDateFormat(item.updated, true) }}
      </div>
    </template>
    <template #cell(fieldwork-comments)="{ item }">
      <div class="">
        {{
          item.comments
            ? item.comments
            : item.event_status === "APPROVED"
            ? "Fieldwork Approved"
            : "Fieldwork Pending"
        }}
      </div>
    </template>
    <template #cell(fieldwork-status)="{ item }">
      <div class="my-1 w-1/2 px-0.5 mx-2">
        <div
          class="rounded-full text-center"
          :class="{
            'bg-purple': item.event_status === 'TODO',
            'bg-alert-warn': item.event_status === 'RETURNED',
            'bg-alert-success': item.event_status === 'APPROVED',
            'bg-orange': item.event_status === 'REJECTED',
            'bg-alert-danger': item.event_status === 'DELETED',
          }"
        >
          <font-awesome-icon
            :class="
              item.event_status === 'RETURNED'
                ? 'text-returned-text'
                : 'text-white'
            "
            :icon="
              item.event_status === 'APPROVED'
                ? 'check'
                : item.event_status === 'RETURNED'
                ? 'reply'
                : item.event_status === 'TODO'
                ? 'hourglass'
                : 'ban'
            "
          />
        </div>
      </div>
    </template>
    <template #cell(fieldwork-date)="{ item }">
      <div class="">
        {{ TableDateFormat(item.fieldwork_date) }}
      </div>
    </template>
    <template #cell(fieldwork-details)="{ item }">
      <button
        aria-label="view-fieldwork-details-button"
        :id="item.fieldwork_id"
        class="w-6 h-6 text-dark-purple bg-white hover:bg-dark-purple hover:text-white border rounded"
        @click="$router.push(`/admin/fieldworks/${item.fieldwork_id}`)"
      >
        <font-awesome-icon icon="clipboard-list" />
      </button>
    </template>
  </dynamic-table>
</template>

<script>
import { get } from "@/composables/httpUtil";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { mapGetters } from "vuex";
import { TableDateFormat } from "@/composables/date_utils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "StudentDetailsFieldworkTab",
  components: { LoadingSpinner, DynamicTable },
  props: {
    id: {
      type: String,
      required: true,
    },
    acad_lvl: {
      type: Object,
      required: true,
    },
  },
  mounted: function () {
    this.fetchStudentEntries();
  },
  data() {
    return {
      entries: [],
      loading: false,

      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 10,
      paginationPage: 1,
      tableConfig: [
        {
          display: "Fieldwork Type",
          name: "fieldwork-type",
          sortable: true,
          class: "",
        },
        {
          display: "Last Updated",
          name: "fieldwork-last-updated",
          sortable: true,
          class: "",
        },
        {
          display: "Messages",
          name: "fieldwork-comments",
          sortable: false,
          class: "",
        },
        {
          display: "Status",
          name: "fieldwork-status",
          sortable: false,
          class: "",
        },
        {
          display: "Date",
          name: "fieldwork-date",
          sortable: true,
          class: "",
        },
        {
          display: "Fieldwork Details",
          name: "fieldwork-details",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  watch: {
    acad_lvl: function () {
      this.fetchStudentEntries();
    },
  },
  methods: {
    TableDateFormat,
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.fetchStudentEntries();
    },
    fetchStudentEntries: function () {
      this.loading = true;
      if (this.acad_lvl.student_at_level_id) {
        let url = `admin/fieldworks?student_id=${this.id}&student_at_level=${this.acad_lvl.student_at_level_id}&page=${this.paginationPage}&limit=${this.itemsPerPage}`;
        get(url).then((response) => {
          this.entries = response.data.sort(
            (a, b) => a.fieldwork_date > b.fieldwork_date
          );
          this.loading = false;
        });
      }
    },
    entry_type: function (type_id) {
      return this.lookup_fieldwork_types.find(
        (type) => type.fieldwork_log_type_id === type_id
      ).fieldwork_type_name;
    },
  },
  computed: {
    ...mapGetters(["lookup_fieldwork_types"]),
  },
};
</script>

<style scoped></style>
