<template>
  <div>
    <div class="mb-5" v-if="!loading">
      <div>
        <div class="header-text mb-3" data-cy="header-admin-reports">
          <span>{{ student_name }}</span>
          <span v-if="isAdm()" class="px-2">
            <button
              v-if="student != null"
              aria-label="masquerade-as-user-button"
              class="w-8 h-8 text-dark-purple hover:bg-dark-purple hover:text-white border rounded"
              @click="masqueradeAs(student.student_id)"
            >
              <font-awesome-icon icon="theater-masks" />
            </button>
          </span>
        </div>
        <div class="subhead-text">
          <div class="flex justify-between items-center">
            <div class="flex justify-start items-center">
              <button
                :class="{
                  'border border-dark-purple rounded-full text-xl text-dark-purple py-1 px-4 mx-2 first:ml-0 text-center peer-checked:bg-dark-purple peer-checked:text-white text-center': true,
                  'bg-dark-purple text-white':
                    acad_lvl.student_at_level_id === lvl.student_at_level_id,
                }"
                :disabled="editing"
                v-for="(lvl, key) in acad_lvls_options"
                :key="key"
                @click="acad_lvl = lvl"
              >
                <span class="block">{{ lvl.level }}</span>
                <span>
                  {{ lvl.term_name }}
                </span>
              </button>
            </div>
            <div v-if="isAdm() || isSra()">
              <button
                v-if="!editing"
                class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white"
                @click="editing = true"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editing">
      <div class="box-style mb-5">
        <h2 class="secondhead-text mb-3">Admin Student Management</h2>
        <div class="grid grid-cols-2 gap-5">
          <div class="border-r border-gray">
            <h3 class="mb-3 thirdhead-text">Student Status</h3>
            <div>
              <select
                v-if="student !== null"
                v-model="promo_filter_status"
                class="text-sm p-1 pl-2 mr-3 mb-3 border-steel-gray rounded h-8 w-2/3 disabled:bg-light-gray"
              >
                <option
                  v-for="status in promo_filter_status_options"
                  :key="status"
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
            <div class="col-span-2 flex justify-end items-center">
              <button
                class="rounded-full text-xl bg-purple hover:bg-dark-purple py-1 px-4 mx-2 text-center text-white"
                @click="updateStudentStatus()"
              >
                Update Student Status
              </button>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-3">
            <div>
              <h3 class="mb-3 thirdhead-text">Academic Year</h3>
              <select
                v-model="promo_filter_acad_lvl"
                class="text-sm p-1 pl-2 mr-3 border-steel-gray rounded h-8 w-full disabled:bg-light-gray"
              >
                <option
                  class="h-8"
                  v-for="(acad_lvl, key) in acad_lvls"
                  :key="key"
                  :value="acad_lvl"
                >
                  {{ acad_lvl.level }}
                </option>
                <option class="h-8" value="Graduate">Graduate</option>
              </select>
            </div>
            <div>
              <h3 class="mb-3 thirdhead-text">Term</h3>
              <select
                class="w-full rounded border-dark-gray py-1 disabled:bg-light-gray"
                v-model="promo_filter_term"
              >
                <option
                  class="h-8"
                  v-for="(term, key) in terms"
                  :key="key"
                  :value="term"
                >
                  {{ term.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2 flex justify-end items-center">
              <button
                class="rounded-full text-xl bg-purple hover:bg-dark-purple py-1 px-4 mx-2 text-center text-white"
                @click="promoteStudent()"
              >
                Promote Student
              </button>
            </div>
          </div>
        </div>
      </div>
      <add-edit-student
        v-if="!loading"
        :student="student"
        :acad_level="acad_lvl"
        :validate="false"
        @submit="putStudent($event, acad_lvl.student_at_level_id)"
        @cancel="editing = false"
        ref="studentProfileForm"
      />
    </div>
    <div class="box-style" v-else>
      <div class="flex flex-wrap w-full">
        <ul
          class="flex mb-0 list-none flex-wrap flex-row border-b border-cool-gray"
        >
          <li class="-mb-px mr-2 last:mr-0 text-center">
            <a
              class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
              v-on:click="toggleTabs(1)"
              v-bind:class="{
                'bg-whisper': openTab !== 1,
                '': openTab === 1,
              }"
            >
              Profile
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 text-center">
            <a
              class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
              v-on:click="toggleTabs(2)"
              v-bind:class="{
                'bg-whisper': openTab !== 2,
                '': openTab === 2,
              }"
            >
              Pairings
            </a>
          </li>
          <li
            v-if="isAdm() || isFac() || isSaj()"
            class="-mb-px mr-2 last:mr-0 text-center"
          >
            <a
              class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
              v-on:click="toggleTabs(3)"
              v-bind:class="{
                'bg-whisper': openTab !== 3,
                '': openTab === 3,
              }"
            >
              Plan
            </a>
          </li>
          <li
            v-if="isAdm() || isFac() || isSaj()"
            class="-mb-px mr-2 last:mr-0 text-center"
          >
            <a
              class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
              v-on:click="toggleTabs(4)"
              v-bind:class="{
                'bg-whisper': openTab !== 4,
                '': openTab === 4,
              }"
            >
              Fieldwork
            </a>
          </li>
          <li v-if="isAdm()" class="-mb-px mr-2 last:mr-0 text-center">
            <a
              class="border border-cool-gray rounded-t text-xl px-8 py-2 block leading-normal cursor-pointer"
              v-on:click="toggleTabs(5)"
              v-bind:class="{
                'bg-whisper': openTab !== 5,
                '': openTab === 5,
              }"
            >
              Notes
            </a>
          </li>
        </ul>
        <div
          class="flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded"
        >
          <div class="px-3 py-5 flex-auto">
            <loading-spinner v-if="loading" />
            <div class="tab-content tab-space" v-else>
              <div
                v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
              >
                <student-profile-view :student="student" :acad_lvl="acad_lvl" />
              </div>
            </div>
            <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
              Pairings:
              <student-details-pairings-tab
                :pairings="pairings"
                :surveys="student?.surveys"
              />
            </div>
            <div v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }">
              <div v-if="plan_loading">Plan not yet created.</div>
              <div v-else v-for="plan in plans" :key="plan.ppdp_id">

                <div class="text-2xl mb-4">
                  <strong>Status:</strong> {{ plan.status }}
                </div>
                <PlanPrint
                  v-if="!plan_loading"
                  :plan="plan"
                  :student="student"
                  :mentor="reviewing_mentor(plan.reviewing_mentor_id)"
                />
              </div>
            </div>
            <div v-bind:class="{ hidden: openTab !== 4, block: openTab === 4 }">
              Fieldwork Entries:
              <student-details-fieldwork-tab :id="id" :acad_lvl="acad_lvl" />
            </div>
            <div v-bind:class="{ hidden: openTab !== 5, block: openTab === 5 }">
              <div
                v-if="openTab === 5"
                class="flex grow items-center justify-end relative"
              >
                <button
                  class="absolute rounded-full bg-purple hover:bg-dark-purple py-1 text-white px-2"
                  v-text="'Add Note'"
                  @click="$refs.student_note_input.editNote()"
                />
              </div>
              <div>
                <add-edit-notes
                  :mentor-id="id"
                  ref="student_note_input"
                  is-student
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, put, post } from "@/composables/httpUtil";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import StudentProfileView from "@/components/reusable-components/StudentProfileView";
import StudentDetailsFieldworkTab from "@/views/admin/students/subcomponents/tabs/StudentDetailsFieldworkTab";
import AddEditNotes from "@/views/admin/mentors/mentor-edits/AddEditNotes";
import StudentDetailsPairingsTab from "@/views/admin/students/subcomponents/tabs/StudentDetailsPairingsTab";
import AddEditStudent from "@/components/reusable-components/AddEditStudent";
import { mapGetters } from "vuex";
import { emitAlert } from "@/composables/alerts";
import { isAdm, isFac, isSaj, isSra } from "@/composables/tokenUtils";
import PlanPrint from "@/views/student/plan/PlanPrint";

export default {
  name: "AdminStudentsDetail",
  components: {
    StudentDetailsPairingsTab,
    AddEditNotes,
    StudentDetailsFieldworkTab,
    StudentProfileView,
    LoadingSpinner,
    AddEditStudent,
    PlanPrint,
  },
  props: {
    _id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: null,
      openTab: 1,

      student: null,
      pairings: [],
      plans: null,

      loading: false,
      plan_loading: true,
      acad_lvl: {},
      acad_lvls_options: [],
      editing: false,

      terms: [],
      promo_filter_term: null,
      promo_filter_acad_lvl: null,
      promo_filter_status: false,
      promo_filter_status_options: [null, "ACTIVE", "INACTIVE"],
    };
  },
  created() {
    this.id = this.$route.params.id ? this.$route.params.id : this._id;
    this.loading = true;
    Promise.all([this.fetchStudent(), this.fetchTerms()]).finally(
      () => (this.loading = false)
    );
  },
  methods: {
    get,
    put,
    post,
    isAdm,
    isSra,
    isSaj,
    isFac,
    updateStudentStatus() {
      this.student.student_status = this.promo_filter_status;
      this.put(`admin/students/${this.id}/status`, {
        status: this.promo_filter_status,
      }).then(() => {
        emitAlert("success", `Student is now ${this.promo_filter_status}`, {
          response: `${this.student_name}'s status has been successfully set to ${this.promo_filter_status} term.`,
          timeout: 10000,
        });
      });
    },
    promoteStudent() {
      this.post("admin/students/promote", {
        students: [this.student],
        acad_level: this.promo_filter_acad_lvl,
        term: this.promo_filter_term,
      })
        .then(() => {
          emitAlert("success", "Student Promoted", {
            response: `${this.student_name} has been successfully promoted for the ${this.promo_filter_term.name} term.`,
            timeout: 10000,
          });
          this.editing = false;
        })
        .then(this.fetchStudent());
    },
    fetchTerms() {
      return this.get("admin/terms").then((terms) => {
        this.terms = terms.data;
        this.promo_filter_term = this.lookup_current_term;
      });
    },
    fetchStudent() {
      return this.get(`admin/students/${this.id}`).then(async (s) => {
        this.student = s;
        this.promo_filter_status = s.student_status;
        this.acad_lvl = this.getCurrentAcadLvl(s.profiles);
        await this.fetchStudentPairings(s.profiles);
      });
    },
    putStudent(student, student_at_level_id = null, redirect = true) {
      let url = `admin/students/${this.id}`;
      if (student_at_level_id !== null) {
        url += `?student_at_level=${student_at_level_id}`;
      }
      this.put(url, student).then((student) => {
        this.student = student;
        emitAlert("success", "Profile Updated", {
          response: `${this.student_name}'s profile has been successfully updated!`,
          timeout: 10000,
        });
        this.editing = !redirect;
      });
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    getCurrentAcadLvl(profiles) {
      profiles
        .sort((a, b) => a.acad_level.value - b.acad_level.value)
        .forEach((p) =>
          this.acad_lvls_options.push({
            acad_level_id: p.acad_level.acad_level_id,
            level: p.acad_level.level,
            student_at_level_id: p.student_at_level_id,
            term_name: p.term.name,
          })
        );
      const current_profile = profiles.find((p) => p.current);
      return {
        acad_level_id: current_profile.acad_level.acad_level_id,
        level: current_profile.acad_level.level,
        student_at_level_id: current_profile.student_at_level_id,
        term_name: current_profile.term.name,
      };
    },
    fetchStudentPairings: function (profiles) {
      let promises = [];
      profiles.forEach((profile) => {
        this.pairings = [];
        let url = `admin/pairings?sal_id=${profile.student_at_level_id}`;
        // Make promises
        let p = new Promise((resolve) => {
          this.get(url)
            .then((response) => {
              this.pairings.push(...response.data);
              resolve();
            })
            .catch((error) => {
              console.error(error);
            });
        });
        promises.push(p);
      });
      Promise.allSettled(promises).finally(() => {
        this.fetchPlan();
      });
    },
    fetchPlan: function () {
      let url = `admin/students/${this.id}/plan?sal_id=${this.acad_lvl.student_at_level_id}`;
      get(url)
        .then((response) => {
          if (response.length) {
            this.plans = response;
          }
          this.plan_loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    masqueradeAs: function (id) {
      this.$store.dispatch("SetIsMasquerading", true);
      this.$store.dispatch("FetchMasqToken", { id: id });
    },
    reviewing_mentor(mentor_id) {
      return this.pairings.find(
          (pairing) => pairing.mentor_id === mentor_id
      )?.mentor;
    },
  },
  computed: {
    acad_lvls() {
      return this.lookup_acad_levels.filter((lvl) => lvl.level !== "N/A");
    },
    student_name() {
      let name = this.student
        ? `${this.student.firstname}
            ${this.student.nickname ? "(" + this.student.nickname + ")" : ""} ${
            this.student.lastname
          }`
        : false;
      if (name) {
        name = `${this.student.prefix ? this.student.prefix : ""} ${name} ${
          this.student.suffix ? this.student.suffix : ""
        }`;
      }
      return name;
    },
    ...mapGetters([
      "lookup_current_term",
      "lookup_acad_levels",
      // "lookup_student_status_options",
    ]),
  },
};
</script>

<style scoped></style>
