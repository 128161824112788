<template>
  <modal
      v-if="displayModal"
      label="Survey Results"
      show_cancel
      cancel_btn_text="Close"
      no_submit
      blocking
      large
      @close="displayModal = false"
  >
    <template v-slot:content>
      <div class="card">
        <div v-if="!isPopupLoading">
          <template v-if="surveyAnswers.fields && surveyAnswers.responses">
            <div
                class="pt-2"
                v-for="(field, key) in surveyAnswers.fields"
                :key="key"
            >
              <div>
                {{ field.name }}
              </div>
              <div class="thirdhead-text">
                {{ findAnswer(field) }}
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <LoadingSpinner />
        </div>
      </div>
    </template>
  </modal>

  <ul class="w-full">
    <li
      v-for="(pairing, key) in pairingsSortedByStart"
      :key="key"
      class="grid grid-cols-3 gap-3 px-2 py-1 even:bg-cool-white odd:bg-light-gray first:rounded-t last:rounded-b"
    >
      <div class="col-span-2" v-if="!pairing.is_pairing_invalid">
        <div v-if="isAdm() || isSra() || isSaj()">
          <a
            :href="`/admin/mentors/view/${pairing.mentor_id}`"
            class="thirdhead-text font-semibold"
          >
            {{ pairing.mentor.prefix ? pairing.mentor.prefix : "" }}
            {{ pairing.mentor.firstname }} {{ pairing.mentor.lastname }}
          </a>
        </div>
        <div v-else>
          {{ pairing.mentor.prefix ? pairing.mentor.prefix : "" }}
          {{ pairing.mentor.firstname }} {{ pairing.mentor.lastname }}
        </div>
        <div class="">
          <span class="font-semibold">Pairing Dates:</span>
          {{ FormatDate(pairing.start_date) }} -
          {{ pairing.end_date ? FormatDate(pairing.end_date) : "Current" }} ({{
            pairing.student_at_level.term.name
          }})
        </div>
      </div>
      <div v-if="isAdm() || isSra()" class="flex justify-end items-center">
        <button
          class="w-40 rounded-full mr-1 last:mr-0 px-3 py-2 bg-white text-dark-purple border-dark-purple border hover:bg-purple hover:bg-opacity-30 cursor-pointer"
          v-text="'View Survey'"
          v-if="surveyExists(pairing.student_at_level)"
          @click="viewSurveyResults(pairing.student_at_level)"
        />

        <button
          class="w-40 rounded-full mr-1 last:mr-0 px-3 py-2 bg-purple text-white hover:bg-dark-purple cursor-pointer"
          v-text="'View Mentor'"
          v-else
          @click="toMentor(pairing.mentor_id)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { FormatDate } from "@/composables/date_utils";
import { isAdm, isFac, isSaj, isSra } from "@/composables/tokenUtils";
import {get} from "@/composables/httpUtil";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import Modal from "@/components/modal/Modal";

export default {
  name: "StudentDetailsPairingsTab",
  components: {
    LoadingSpinner,
    Modal,
  },
  props: {
    pairings: {
      type: Array,
      default: () => [],
    },
    surveys: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      isPopupLoading: false,
      displayModal: false,
    };
  },
  methods: {
    FormatDate,
    isAdm,
    isSra,
    isFac,
    isSaj,
    get,
    toMentor(person_id) {
      this.$router.push("/admin/mentors/view/" + person_id);
    },
    surveyExists(sal) {
      return this.surveys.find((s) => {
        return (
          s.term_id === sal.term.term_id &&
          s.student_id === sal.student.person_id
        );
      });
    },
    viewSurveyResults(sal) {
      let survey = this.surveyExists(sal);
      this.displayModal = true;
      this.isPopupLoading = true;
      this.get(`admin/surveys/response/${survey.id}`).then(
        (results) => {
          this.surveyAnswers = results;
          this.isPopupLoading = false;
        },
        () => {
          this.isPopupLoading = false;
          this.displayModal = false;
        }
      );
    },
    findAnswer(field) {
      let answer = "No response found";
      const response = this.surveyAnswers.responses.find(
          (response) => response.field_id === field.field_id
      );

      if (response !== undefined) {
        if (response.value && response !== "") {
          answer = response.value;
        } else {
          answer = "No response given";
        }
      }
      return answer;
    },
  },
  computed: {
    pairingsSortedByStart: function () {
      let output = [...this.pairings];
      return output.sort((a, b) => {
        return (
          new Date(a.student_at_level.term.start_date) -
          new Date(b.student_at_level.term.start_date)
        );
      });
    },
  },
};
</script>
