<template>
  <div class="grid gap-6">
    <div class="box-style" data-cy="content-contact-info">
      <div class="secondhead-text">Primary Contact</div>
      <div class="subhead-text">
        This is your primary contact information at the University of St.
        Thomas. Please update any incorrect information at the
        <a
          href="https://www.stthomas.edu/registrar"
          data-cy="profile-registrarlink"
          class="anchor"
          target="_blank"
          rel="noopener noreferrer"
          >registrar website</a
        >.
      </div>
      <div class="grid grid-cols-2 grid-rows-1 pb-3">
        <div class="row-start-1">
          <div data-cy="student-first-last-name" class="font-bold">
            {{ student.firstname }} {{ student.lastname }}
          </div>
        </div>
        <div class="row-start-2">
          <div>
            <a data-cy="student-primary-email">{{
              primaryEmail?.email_address
            }}</a>
          </div>
          <div v-if="primaryPhone" data-cy="student-phone-number">
            ({{ primaryPhone?.phone_number.area_code }})
            {{ primaryPhone?.phone_number.prefix }}-{{
              primaryPhone?.phone_number.line
            }}
          </div>
          <div v-else>No Primary Phone</div>
        </div>
        <div class="row-start-2">
          <div data-cy="student-address-l1">
            {{ primaryAddress?.address_line_one }}
          </div>
          <div
            data-cy="student-address-l2"
            v-if="primaryAddress?.address_line_two !== null"
          >
            {{ primaryAddress?.address_line_two }}
          </div>
          <div data-cy="student-city-this.student-zip">
            {{ primaryAddress?.city }},
            {{ lookup_states[primaryAddress?.state] }}
            {{ primaryAddress?.zip_code }}
          </div>
        </div>
      </div>
      <div class="secondhead-text">Local Contact</div>
      <div class="grid grid-cols-2 grid-rows-1 pb-3">
        <div class="row-start-1">
          <div data-cy="local-contact-first-last" class="font-bold">
            {{
              `${student.nickname ? student.nickname : student.firstname} ${
                student.lastname
              }`
            }}
          </div>
        </div>
        <div class="row-start-2">
          <div>
            <a data-cy="profile-local-email">{{
              localEmail ? localEmail.email_address : ""
            }}</a>
          </div>
          <div data-cy="profile-local-phone" v-if="localPhone">
            ({{ localPhone?.phone_number.area_code }})
            {{ localPhone?.phone_number.prefix }}-{{
              localPhone?.phone_number.line
            }}
          </div>
        </div>
        <div class="row-start-2">
          <div v-if="localAddress">
            <div data-cy="profile-local-addressl1">
              {{ localAddress?.address_line_one }}
            </div>
            <div
              data-cy="profile-local-addressl2"
              v-if="localAddress.address_line_two !== null"
            >
              {{ localAddress?.address_line_two }}
            </div>
            <div data-cy="profile-local-citythis.studentzip">
              {{ localAddress?.city }},
              {{ lookup_states[localAddress?.state] }}
              {{ localAddress?.zip_code }}
            </div>
          </div>
        </div>
      </div>
      <div class="profile_box">
        <div class="pb-3">
          <div
            data-cy="profile-transportation-section-title"
            class="secondhead-text"
          >
            Transportation
          </div>
          <div data-cy="profile-transport-access" class="">
            {{
              student_profile?.vehicle_access
                ? compute_transportation
                : "I haven't specified"
            }}
            {{
              compute_vehicle_access_other
                ? ": " + compute_vehicle_access_other
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="box-style" data-cy="content-program-details">
      <div class="secondhead-text">My Program Details</div>
      <div class="profile_box">
        <div class="thirdhead-text pb-3">Interests</div>
        <ol class="list-decimal px-3 pb-3">
          <li
            data-cy="profile-pairing-pref-interests"
            v-for="(interest, key) in student_profile.interest_areas ?? []"
            :key="`interest_${key}`"
          >
            {{ interest.description }}
          </li>
        </ol>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text pb-3" data-cy="profile-pairing-pref-title">
          Pairing preferences
        </div>
        <ol class="list-decimal px-3 pb-3" v-if="student_profile">
          <li
            data-cy="profile-pairing-pref-options"
            v-for="(value, key) in student_profile.pairing_factors ?? []"
            :key="key"
          >
            {{ value.description }}
          </li>
        </ol>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text pb-3">Other Considerations</div>
        <div
          data-cy="profile-other-considerations-displayed"
          v-if="student_profile"
        >
          {{ student_profile?.pairing_considerations }}
        </div>
      </div>
    </div>
    <div class="box-style" data-cy="content-about-me">
      <div class="secondhead-text">About Me</div>
      <div class="profile_box">
        <div class="thirdhead-text">Prefix</div>
        <div class="pb-3">
          {{ student.prefix === "" ? "Not Available" : student.prefix }}
        </div>
        <div class="thirdhead-text">Preferred Name</div>
        <div class="pb-1">
          {{
            student.nickname === ""
              ? "Add the name that you prefer us to use by updating your profile information."
              : student.nickname
          }}
        </div>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">Gender</div>
        <div class="pb-1 inline-block pr-1">{{ student.gender }}</div>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">Pronouns</div>
        <div class="pb-1 inline-block pr-1">{{ student.pronouns }}</div>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">Ethnicity</div>
        <div class="pb-1">
          <ul>
            <li v-for="(value, key) in student.ethnicity" :key="key" class="">
              {{ value.race }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="box-style" data-cy="content-education">
      <div data-cy="profile-education-title" class="secondhead-text">
        Education
      </div>
      <div v-for="school in student.education" :key="school.name" class="pb-1">
        <div data-cy="profile-school-name" class="fourthhead-text">
          {{ school.school }}
        </div>
        <div data-cy="profile-degree-fos-name">
          {{ school.degree }}, {{ school.field_of_study }}
        </div>
        <div data-cy="profile-grad-year">{{ school.graduation_year }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StudentProfileView",
  props: {
    student: {
      type: Object,
    },
    acad_lvl: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["lookup_states"]),
    student_profile() {
      let profile = undefined;
      if (this.student.profiles.length === 0) {
        return profile;
      } else if (this.student.profiles.length === 1) {
        profile = this.student.profiles[0];
      } else if (this.acad_lvl) {
        profile = this.student.profiles.find(
          (p) => p.student_at_level_id === this.acad_lvl.student_at_level_id
        );
      } else {
        profile = [...this.student.profiles].find(
          (p) => p.current === true
        );
      }
      profile.interest_areas = profile.interest_areas?.sort(
        (a, b) => a.priority - b.priority
      );
      profile.pairing_factors = profile.pairing_factors?.sort(
        (a, b) => a.priority - b.priority
      );
      return profile;
    },
    primaryAddress() {
      return this.student.address.find(
        (address) =>
          address.is_primary && address.address_type !== "STUDENT_LOCAL"
      );
    },
    primaryEmail() {
      return this.student.email.find((email) => email.is_primary);
    },
    primaryPhone() {
      const phone = this.student.phone.find((number) => number.is_primary);
      if (phone) {
        if (typeof phone.phone_number !== "object") {
          const phone_number = phone.phone_number.split("-");
          phone.phone_number = {
            area_code: phone_number[0],
            prefix: phone_number[1],
            line: phone_number[2],
          };
        }
        return phone;
      } else {
        return false;
      }
    },
    localAddress() {
      return this.student.address.find(
        (address) => address.address_type === "STUDENT_LOCAL"
      );
    },
    localEmail() {
      return this.student.email.find(
        (email) => email.email_type === "STUDENT_LOCAL"
      );
    },
    localPhone() {
      const phone = this.student.phone.find(
        (number) => number.phone_type === "STUDENT_LOCAL"
      );
      if (phone) {
        if (typeof phone.phone_number !== "object") {
          const phone_number = phone.phone_number.split("-");
          phone.phone_number = {
            area_code: phone_number[0],
            prefix: phone_number[1],
            line: phone_number[2],
          };
        }
        return phone;
      } else {
        return false;
      }
    },
    compute_transportation: function () {
      switch (this.student_profile.vehicle_access) {
        case "TRANSIT":
          return "Public Transportation";
        case null:
          return "I haven't specified";
        case "NONE":
          return "No transportation available";
        default:
          return (
            this.student_profile.vehicle_access.charAt(0) +
            this.student_profile.vehicle_access.slice(1).toLowerCase()
          );
      }
    },
    compute_vehicle_access_other: function () {
      return this.student_profile?.vehicle_access === "OTHER"
        ? this.student_profile.vehicle_access_other
        : null;
    },
  },
};
</script>

<style scoped></style>
